import React, { useState } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { Container, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Hero } from '../components/Home/Hero';
import { Testimonials } from '../components/Testimonials';
import { Resources } from '../components/General/Resources';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../components/WaveSVGs/WaveDownSVG';
import { SEO } from '../components/SEO';
import { Intro } from '../components/Home/Intro';
import { Offerings } from '../components/Home/Offerings';
import { HeroModal } from '../components/Home/Hero/HeroModal';
import { CustomerLogos } from '../components/CustomerLogos';
import { PromotionModal } from '../components/General/PromotionModal';
import { HomeAwardCta } from '../components/Home/HomeAwardCta';

const Industry = loadable(() => import('../components/Home/Industry'));
const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	testimonialTitle: {
		color: theme.workwaveBlue,
		textAlign: 'center',
		fontWeight: 'bold',
		marginBottom: '2rem',
		marginTop: '2rem',
	},

	centerBackground: {
		background:
			'linear-gradient(339.51deg, #002D5C 26.73%, #055291 46.94%, #2F7FC1 67.6%, #002D5C 88.26%)',
		padding: '6rem 0',
	},
	formBackground: {
		background: theme.workwaveBlue,
		padding: '24rem 0',
		[theme.breakpoints.down('md')]: {
			padding: '14rem 0',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '6rem 0',
		},
	},
}));

const IndexPage = ({ data, location }) => {
	const home = data.home.edges[0].node;
	const classes = useStyles();

	const [heroModalOpen, setHeroModalOpen] = useState(false);

	const {
		metaTitle,
		metaDescription,
		hero,
		_rawIntroHeader,
		introCards,
		offerings,
		offeringsHeader,
		learnMoreHeader,
		formCtaText,
		industriesHeader,
		industriesBody,
		industriesViewAll,
		testimonialHeader,
		testimonials,
		customerLogos,
		customerLogosHeader,
		marketoId,
		pardotUrl,
		contactForm,
		resources,
		resourceTitle,
		_rawResourceBody,
		productLogosHeader,
		productLogos,
		promotionModal,
		homeAwardCta,
	} = home;

	const { industries } = data;
	const sm = useMediaQuery('(max-width: 600px)');
	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
			<Hero
				hero={hero}
				industries={industries}
				heroIndustries={hero.heroIndustries}
				homeHeroVideo={hero.homeHeroVideo}
				setHeroModalOpen={setHeroModalOpen}
			/>
			{homeAwardCta.ctaLink ? (
				<Container>
					<HomeAwardCta
						background={homeAwardCta.ctaBackground}
						header={homeAwardCta._rawCtaBlockHeader}
						ctaLink={homeAwardCta.ctaLink}
						ctaText={homeAwardCta.ctaText}
						internalLink={homeAwardCta.internalLink}
					/>
				</Container>
			) : null}

			<Intro header={_rawIntroHeader} cards={introCards} />

			<Offerings
				header={offeringsHeader}
				offerings={offerings}
				learnHeader={learnMoreHeader}
				ctaText={formCtaText}
				productLogosHeader={productLogosHeader}
				productLogos={productLogos}
			/>
			<div style={{ marginTop: sm ? '0rem' : '-4rem' }}>
				<WaveDownSVG height='213' width='100%' fill='white' />
			</div>

			<div className={classes.centerBackground}>
				<Industry
					industries={industries}
					header={industriesHeader}
					body={industriesBody}
					allLink={industriesViewAll}
				/>
			</div>
			<WaveUpSVG height='213' width='100%' fill='white' />
			<div
				style={{
					background: 'transparent',
					padding: '2rem 0',
				}}>
				<Testimonials header={testimonialHeader} testimonials={testimonials} />
			</div>
			<Container>
				<CustomerLogos
					customerLogosArray={customerLogos}
					customerLogosHeader={customerLogosHeader}
				/>
			</Container>
			<WaveDownSVG height='213' width='100%' fill='white' />
			<div className={classes.formBackground}>
				<Form
					formId={marketoId}
					pardotUrl={pardotUrl}
					contactForm={contactForm}
					privacy
					location={location}
				/>
			</div>
			<WaveUpSVG height='213' width='100%' fill='white' />
			<Container>
				<Resources
					resources={resources}
					header={resourceTitle}
					subheader={_rawResourceBody}
				/>
			</Container>
			<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
			<HeroModal
				open={heroModalOpen}
				setHeroModalOpen={setHeroModalOpen}
				homeHeroVideo={hero.homeHeroVideo}
				playing
			/>
			{!!promotionModal ? (
				<PromotionModal promotionModal={promotionModal} />
			) : null}
		</>
	);
};

export const query = graphql`
	query HomePageQuery {
		home: allSanityHome {
			edges {
				node {
					metaTitle
					metaDescription
					hero {
						_rawContent
						mobileBackground {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						backgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						heroIndustries {
							title
							slug {
								current
							}
							faIcon
							homeHeroPillTitle
							accentColor {
								hexValue
							}
						}
						homeHeroVideo
					}
					promotionModal {
						backgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						link
					}
					productLogosHeader
					productLogos {
						asset {
							gatsbyImageData(
								placeholder: BLURRED
								height: 65
								# width: 250
								# fit: FILLMAX
							)
						}
					}
					homeAwardCta {
						ctaHeader
						ctaBackground {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						ctaLink
						internalLink
						ctaText
						_rawCtaBlockHeader
					}
					_rawIntroHeader
					introCards {
						faIcon
						header
						Body
						accentColor {
							hexValue
						}
					}
					offeringsHeader
					offerings
					learnMoreHeader
					formCtaText
					industriesHeader
					industriesBody
					industriesViewAll
					testimonialHeader
					testimonials {
						title
						header
						testimonialLogo {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						testimonialText
						nameAndTitle
						videoVariant
						company
						image {
							asset {
								gatsbyImageData(
									fit: FILLMAX
									height: 500
									width: 750
									placeholder: BLURRED
								)
							}
						}
					}
					contactForm {
						header
						privacyPolicy
					}
					marketoId
					pardotUrl
					resourceTitle
					_rawResourceBody
					resources {
						title
						blurb
						image {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						ctaText
						ctaLink
					}
					customerLogos {
						logo {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
					}
					customerLogosHeader
				}
			}
		}
		industries: allSanityIndustries {
			edges {
				node {
					title
					metaTitle
					metaDescription
					slug {
						current
					}
					accentColor {
						hexValue
					}
					faIcon
					_rawSynopsis

					mosaicType {
						title
					}
					mosaicBackground {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					mosaicPrimary
					resources {
						title
						blurb
						image {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						ctaText
						ctaLink
					}
				}
			}
		}
	}
`;

export default IndexPage;
